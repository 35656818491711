<template>
  <div class="shop-products-slider">
    <Transition name="fade-in">
      <AppIcon
        v-if="activeIndex > 0 && !clientDeviceStore.isMobile"
        key="slider-arrow-left"
        icon="slider-arrow"
        class="shop-products-slider__navigation is-left"
        :is-raw="true"
        @click="changeSlide('prev')"
        @keypress="changeSlide('prev')"
      />
    </Transition>

    <swiper
      v-if="computedProducts && computedProducts.length > 0"
      :slides-per-view="itemsPerView"
      :space-between="20"
      @swiper="onSwiper"
    >
      <swiper-slide
        v-for="product in computedProducts"
        :key="`product-${product.id}`"
        class="shop-prodcuts-slider__slide"
      >
        <div class="shop-products-slider__slide-inner" :style="`transform: translateX(${-(swipeOffset)}px);`">
          <div class="shop-products-slider__image">
            <AtomImage
              v-if="product.attributes?.teaser_image?.data?.attributes || product.teaser_image"
              :data="product.attributes?.teaser_image?.data?.attributes || product.teaser_image"
              :is-strapi="true"
              :disable-lazy="true"
            />
          </div>

          <div class="shop-products-slider__productname">
            {{ product.attributes?.label || product.label }}
          </div>
        </div>
      </swiper-slide>
    </Swiper>

    <div v-if="clientDeviceStore.isMobile" class="shop-products-slider__bullets">
      <span
        v-for="(product, index) of products"
        :key="`bullet-${product.id}`"
        class="shop-products-slider__bullet"
        :class="{ 'is-active': index === activeIndex }"
      />
    </div>

    <Transition name="fade-in">
      <AppIcon
        v-if="activeIndex + itemsPerView < products.length && !clientDeviceStore.isMobile"
        key="slider-arrow-right"
        icon="slider-arrow"
        class="shop-products-slider__navigation is-right"
        :is-raw="true"
        @click="changeSlide('next')"
        @keypress="changeSlide('next')"
      />
    </Transition>
  </div>
</template>

<script setup>
const props = defineProps({
    products: {
        type: Array,
        default: () => [],
    },
});

const {
    Swiper,
    SwiperSlide,
} = useSwiperjs();

/*
    Settings
*/
const clientDeviceStore = useClientDeviceStore();
const itemsPerView = computed(() => {
    if (clientDeviceStore.isMobile) return 1;
    if (clientDeviceStore.isTablet) return 2;
    return 3;
});

/*
    Init Swiper
*/
const swiperController = ref(null);
const onSwiper = (e) => {
    swiperController.value = e;
};

/*
    Controlls
*/
const changeSlide = (dir) => {
    if (dir === 'next') {
        swiperController.value.slideNext();
    }

    if (dir === 'prev') {
        swiperController.value.slidePrev();
    }
};

/*
    Active Index -> Used for bullet points
*/
const activeIndex = computed(() => swiperController.value?.realIndex);

/*
  Product mapping
*/
const computedProducts = computed(() => props.products.attributes || props.products);
</script>

<style lang="scss" scoped>
.shop-products-slider {
    position: relative;
}

.shop-prodcuts-slider__slide {
    // aspect ratio calculated by designs width and height 1 / 359 * 334
    aspect-ratio: 0.985;
}
.shop-products-slider__slide {
    position: relative;
    width: calc(100% / v-bind('itemsPerView'));
    padding: 10px;
    aspect-ratio: 0.985;

    * {
        pointer-events: none;
        user-select: none;
    }

    @include mobile {
        padding: 0;
    }
}

.shop-products-slider__slide-inner {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    border-radius: 8px;
    background: $C_WHITE;
}

.shop-products-slider__image {
    height: 100%;
    padding: 20px;

    .atom-image {
        position: relative;
        width: 100%;
        height: 100%;
    }
}

.shop-products-slider__productname {
    @include typo-font('regular');
    @include typo-size('p');

    height: 75px;
    padding-right: 20px;
    padding-bottom: 38px;
    padding-left: 20px;

    color: $C_GREEN_DARK;
    text-align: center;
    white-space: normal;

    @include mobile {
        padding-bottom: 60px;
    }
}

.shop-products-slider__navigation {
    @include z-index('bullets');

    position: absolute;
    top: 0;
    bottom: 0;
    width: 45px;
    height: 45px;
    margin: auto;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;

    &.is-right {
        right: 0;
        transform: translateX(50%);

        &:hover {
            transform: translateX(50%) scale(1.025);
        }
    }

    &.is-left {
        left: 0;
        transform: rotate(-180deg) translateX(50%);

        &:hover {
            transform: rotate(-180deg) translateX(50%) scale(1.025);
        }
    }
}

.shop-products-slider__bullets {
    @include z-index('bullets');

    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    padding: 16px;
    background: $C_GREEN_BRIGHT;
    border-top-right-radius: 6px;
    column-gap: 11px;
}

.shop-products-slider__bullet {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: $C_PRIMARY;
    transition: background 0.25s ease-in-out;

    &.is-active {
        background: $C_GREEN_DARK;
    }
}
</style>
